// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify';
// import colors from 'vuetify/lib/util/colors'

import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        primary: '#91b509',
        secondary: '#0098dd',
        link: '#1976D2',
        'new-primary': '#8CC63F',
        'new-secondary': '#00ABE5',
        'new-black': '#343434',
        // danger: colors.red.lighten2,
        // accent: '#8c9eff',
        // error: '#b71c1c',
      },
    },
  },
});
