import { auth } from '@/lib/firebase';
import api from '@/lib/api';

export const MUTATIONS = {};

export const GETTERS = {};

const RESET = 'RESET';

const getDefaultState = () => ({
  unsubscribers: [],
});

export const state = getDefaultState();

export const mutations = {
  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters = {};

export const actions = {
  async fetchPatients(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/patients',
        {
          headers: { Authorization: `Bearer ${token}` },
          params: payload,
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchPatientsNames(_, doctorId) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        '/patients/names',
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { doctorId },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async fetchPatient(_, id) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.get(
        `/patients/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async addPatient(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.post(
        '/patients',
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  async updatePatient(_, payload) {
    const token = await auth.currentUser.getIdToken();

    try {
      const response = await api.put(
        `/patients/${payload.id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters[GETTERS.UNSUBSCRIBERS];

    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(MUTATIONS.UNSUBSCRIBERS, []);
    }
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
