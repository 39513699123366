import { auth } from '@/lib/firebase';
import api from '@/lib/api';

const SET_CARDS = 'SET_CARDS';
const SET_ADDRESSES = 'SET_ADDRESSES';
const SET_INFO = 'SET_INFO';
const SET_IS_ADDING_CARD = 'SET_IS_ADDING_CARD';
const SET_IS_ADDING_ADDRESS = 'SET_IS_ADDING_ADDRESS';
const SET_IS_LOADING = 'SET_IS_LOADING';

const GET_CARDS = 'GET_CARDS';
const GET_ADDRESSES = 'GET_ADDRESSES';
const GET_INFO = 'GET_INFO';
const GET_IS_ADDING_CARD = 'GET_IS_ADDING_CARD';
const GET_IS_ADDING_ADDRESS = 'GET_IS_ADDING_ADDRESS';
const GET_IS_LOADING = 'GET_IS_LOADING';

const RESET = 'RESET';

const getDefaultState = () => ({
  addresses: [],
  cards: [],
  info: {},
  isAddingAddress: false,
  isAddingCard: false,
  isLoading: false,
});

export const state = getDefaultState();

export const mutations = {
  [SET_ADDRESSES](state, addresses) {
    state.addresses = addresses;
  },
  [SET_CARDS](state, cards) {
    state.cards = cards;
  },
  [SET_INFO](state, info) {
    state.info = info;
  },
  [SET_IS_ADDING_ADDRESS](state, isAddingAddress) {
    state.isAddingAddress = isAddingAddress;
  },
  [SET_IS_ADDING_CARD](state, isAddingCard) {
    state.isAddingCard = isAddingCard;
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },

  [RESET](state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters = {
  [GET_ADDRESSES](state) {
    return state.addresses;
  },
  [GET_CARDS](state) {
    return state.cards;
  },
  [GET_INFO](state) {
    return state.info;
  },
  [GET_IS_ADDING_ADDRESS](state) {
    return state.isAddingAddress;
  },
  [GET_IS_ADDING_CARD](state) {
    return state.isAddingCard;
  },
  [GET_IS_LOADING](state) {
    return state.isLoading;
  },
}

export const actions = {
  fetch({ commit }) {
    return new Promise((resolve, reject) => {
      commit(SET_IS_LOADING, true);
      auth.currentUser.getIdToken().then((token) => {
        api.get(
          '/billing/info',
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
          const {
            addresses,
            cards,
            lastUsedCard,
            lastUsedAddress,
            preferredPaymentMethod,
          } = response.data;

          commit(SET_ADDRESSES, addresses);
          commit(SET_CARDS, cards);
          commit(SET_INFO, {
            lastUsedCard,
            lastUsedAddress,
            preferredPaymentMethod,
          });
          commit(SET_IS_LOADING, false);

          resolve();
        })
        .catch((err) => {
          commit(SET_IS_LOADING, false);
          reject(err);
        });
      }).catch((err) => {
        commit(SET_IS_LOADING, false);
        reject(err);
      });
    });
  },

  async setPreferredPaymentMethod({commit, getters}, method) {
    const token = await auth.currentUser.getIdToken();

    try {
      await api.post(
        '/billing/preferredPaymentMethod',
        { method },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      commit(SET_INFO, {
        ...getters.GET_INFO,
        preferredPaymentMethod: method,
      });
    } catch (error) {
      if (error.response) {
        throw error.response.data;
      }

      throw error;
    }
  },

  addCard({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      commit(SET_IS_ADDING_CARD, true);
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/billing/card',
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const { card, address } = response.data;
          const newCards = [
            ...getters.GET_CARDS.filter((c) => c.id !== card.id),
            card,
          ];

          const newAddresses = [
            ...getters.GET_ADDRESSES.filter((c) => c.id !== address.id),
            address,
          ];

          commit(SET_CARDS, newCards);
          commit(SET_ADDRESSES, newAddresses);
          commit(SET_IS_ADDING_CARD, false);

          resolve(card);
        })
        .catch((error) => {
          commit(SET_IS_ADDING_CARD, false);
          
          if (error.response) {
            return reject(error.response.data);
          }
    
          reject(error);
        });
      })
      .catch((error) => {
        commit(SET_IS_ADDING_CARD, false);
        
        if (error.response) {
          return reject(error.response.data);
        }
  
        reject(error);
      });
    })
  },

  selectCard({ commit, getters }, cardId) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/billing/card/select',
          { cardId },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          commit(SET_INFO, {
            ...getters.GET_INFO,
            lastUsedCard: cardId,
          });
          resolve();
        })
        .catch(reject);
      })
      .catch(reject);
    })
  },

  removeCard({ commit }, cardId) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.delete(
          '/billing/card',
          {
            params: { cardId },
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          commit(SET_CARDS, response.data);

          resolve();
        })
        .catch(reject);
      })
      .catch(reject);
    })
  },

  addAddress({ commit, getters }, address) {
    return new Promise((resolve, reject) => {
      commit(SET_IS_ADDING_ADDRESS, true)
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/billing/address',
          { address },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          const newAddress = response.data;
          const newAddresses = [
            ...getters.GET_ADDRESSES.filter((c) => c.id !== newAddress.id),
            newAddress,
          ];

          commit(SET_ADDRESSES, newAddresses);
          commit(SET_IS_ADDING_ADDRESS, false);

          resolve(newAddress);
        })
        .catch((err) => {
          commit(SET_IS_ADDING_ADDRESS, false);
          reject(err);
        });
      })
      .catch((err) => {
        commit(SET_IS_ADDING_ADDRESS, false);
        reject(err);
      });
    });
  },

  selectAddress({ commit, getters }, addressId) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.post(
          '/billing/address/select',
          { addressId },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          commit(SET_INFO, {
            ...getters.GET_INFO,
            lastUsedAddress: addressId,
          });
          resolve();
        })
        .catch(reject);
      })
      .catch(reject);
    })
  },

  removeAddress({ commit }, addressId) {
    return new Promise((resolve, reject) => {
      auth.currentUser.getIdToken().then((token) => {
        api.delete(
          '/billing/address',
          {
            params: { addressId },
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          commit(SET_ADDRESSES, response.data);
          resolve();
        })
        .catch(reject);
      })
      .catch(reject);
    })
  },

  reset({ commit }) {
    commit(RESET);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
