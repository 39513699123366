import { collections } from '@/constants/collections';
import { firestoreDb, handleSnapshot } from '@/lib/firebase';

export const MUTATIONS = {
  SAAS_SUBSCRIPTION: 'SET_SAAS_SUBSCRIPTION',
  UNSUBSCRIBERS: 'SET_UNSUBSCRIBERS',
};

export const GETTERS = {
  SAAS_SUBSCRIPTION: 'GET_SAAS_SUBSCRIPTION',
  UNSUBSCRIBERS: 'GET_UNSUBSCRIBERS',
};

const RESET = 'RESET';

const getDefaultState = () => ({
  saasSubscription: null,
  unsubscribers: [],
});

export const state = getDefaultState();

export const mutations = {
  [MUTATIONS.SAAS_SUBSCRIPTION](state, subscription) {
    state.saasSubscription = subscription;
  },
  [MUTATIONS.UNSUBSCRIBERS](state, unsubscribers) {
    state.unsubscribers = unsubscribers;
  },

  [RESET](state) {
    if (state.unsubscribers) {
      state.unsubscribers.forEach((p) => p());
    }

    Object.assign(state, getDefaultState());
  },
}

export const getters = {
  [GETTERS.SAAS_SUBSCRIPTION](state) {
    return state.saasSubscription;
  },
  [GETTERS.UNSUBSCRIBERS](state) {
    return state.unsubscribers;
  },
};

export const actions = {
  unsubscribeAll({ getters, commit }) {
    const prevUnsubscribers = getters[GETTERS.UNSUBSCRIBERS];

    if (prevUnsubscribers && prevUnsubscribers.length > 0) {
      prevUnsubscribers.forEach((p) => p());
      commit(MUTATIONS.UNSUBSCRIBERS, null);
    }
  },

  subscriberAll({ commit, dispatch, rootGetters }, saasId) {
    dispatch('unsubscribeAll');

    const uid = rootGetters['user/GET_USER_UID'];

    let query = firestoreDb
      .collection(collections.subscriptions)
      .where('product', '==', 'saas');

    if (saasId) {
      query = query.where('productId', '==', saasId);
    } else {
      query = query.where('userId', '==', uid);
    }

    const unsubscriber = query
      .onSnapshot((snapshot) => {
        const results = handleSnapshot(snapshot);
        commit(MUTATIONS.SAAS_SUBSCRIPTION, results[0]);
      });

    commit(MUTATIONS.UNSUBSCRIBERS, [ unsubscriber ]);
  },

  reset({ commit, dispatch }) {
    dispatch('unsubscribeAll');
    commit(RESET);
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
