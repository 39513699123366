<template>
  <v-list nav dense>
    <v-subheader v-if="title">{{ title }}</v-subheader>

    <v-list-item-group
      v-model="group"
      active-class="deep-purple--text text--accent-4"
      mandatory
    >
      <slot name="top-menus"></slot>

      <v-list-item
        :key="`sidebar-doctor-item-${index}`"
        :to="tab.to"
        :href="tab.href"
        :target="tab.target"
        :disabled="!tab.ready || tab.disabled || disabled"
        link
        v-for="(tab, index) in tabs"
      >
        <v-list-item-icon>
          <v-icon :color="tab.alert ? 'red': ''"> {{ tab.icon }} </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            <v-badge :content="'!'" color="red" v-if="tab.alert" class="mt-0" inline>
              {{ tab.title }}
            </v-badge>

            <v-badge
              class="mt-0"
              color="red"
              :content="chatUnreadCountForSection[tab.notificationKey]"
              :value="chatUnreadCountForSection[tab.notificationKey]"
              inline
              v-else-if="tab.notificationKey"
            >
              {{ tab.title }}
            </v-badge>

            <span v-else>
              {{ tab.title }}
            </span>

            <v-chip color="primary" class="ml-2" small dark outlined v-if="!tab.ready && tab.soon">
              Em breve
            </v-chip>

            <v-chip color="primary" class="ml-2" small dark outlined v-if="tab.new">
              Novo
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <slot name="bottom-menus"></slot>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'SidebarMenu',

  props: {
    tabs: {
      type: Array,
      default: () => [],
    },

    title: String,
    disabled: Boolean,
  },

  data() {
    return {
      group: null,
    };
  },
}
</script>
